import React from 'react';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, LineController, PieController } from 'chart.js';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PieController,
    LineController,
);

const ChartJX = ({ type, labels, datasets }) => {
    const bg = ['#e95849', '#23126e', '#36b9cc'];

    return (
        <React.Fragment>
            {type === 'pie' && (
                <Chart
                    type="pie"
                    options={{
                        plugins: {},
                        responsive: true,
                        maintainAspectRatio: false,
                    }}
                    data={{
                        labels,
                        datasets: [
                            {
                                data: datasets,
                                backgroundColor: bg,
                            }
                        ],
                    }}
                // height={func.isMobile ? 200 : 350}
                />
            )}

            {type === 'line' && (
                <Chart
                    type="line"
                    options={{
                        plugins: {
                            legend: false,
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    }}
                    data={{
                        labels,
                        datasets: (datasets || []).map((ds, i) => {
                            return {
                                data: ds,
                                backgroundColor: bg[i],
                            };
                        }),
                    }}
                // height={func.isMobile ? 200 : 350}
                />
            )}
        </React.Fragment>
    );
};

export default ChartJX;