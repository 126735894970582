import React from 'react';

const USSD = React.lazy(() => import('../screens/USSD'));
const Users = React.lazy(() => import('../screens/Users'));
const Awards = React.lazy(() => import('../screens/Awards'));
const Reports = React.lazy(() => import('../screens/Reports'));
const Settings = React.lazy(() => import('../screens/Settings'));
const Dashboard = React.lazy(() => import('../screens/Dashboard'));
const Transfers = React.lazy(() => import('../screens/Organisations/Transfers'));
const Competitions = React.lazy(() => import('../screens/Competitions'));
const Organisations = React.lazy(() => import('../screens/Organisations'));

var routes = [
    { code: 'dash', path: '*', element: <Dashboard />, },
    { code: 'awd', path: '/awards', element: <Awards />, },
    { code: 'org', path: '/organisations/*', element: <Organisations />, },
    { code: 'trs', path: '/transfers', element: <Transfers />, },
    { code: 'usr', path: '/users/*', element: <Users />, },
    { code: 'cmp', path: '/competitions', element: <Competitions />, },
    { code: 'rep', path: '/reports/*', element: <Reports />, },
    { code: 'set', path: '/settings/*', element: <Settings />, },
    { code: 'ussd', path: '/ussd', element: <USSD />, },
];

export default routes;