import React from 'react';

const Search = ({ kay, onSearch }) => {

    React.useEffect(() => {

    }, []);

    return (
        <React.Fragment>
            <div className="row mb-4">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                    <input
                        className="form-control form-control-lg text-center text-xs"
                        placeholder={`Search ${kay}...`}
                        onChange={e => onSearch(e.target.value)}
                    />
                </div>
                <div className="col-lg-4"></div>
            </div>
        </React.Fragment>
    );
};

export default Search;