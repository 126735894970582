import axios from 'axios';
import { func } from '.';
import moment from 'moment';

export const signOutSuccess = (path = '/') => {
    func.delStorage('token');
    func.delStorage('user');
    func.redirect(path);
};

export const apnData = (obj) => {
    const body = new FormData();
    for (var p in obj) {
        if (p === 'file') {
            body.append('file[0]', obj[p]);
        } else if (p === 'image') {
            body.append('image[0]', obj[p]);
        } else {
            body.append(p, obj[p]);
        }
    }
    return body;
}

export const apiHeaders = (req, type = '', method = '') => {
    func.initialize();
    const navigator = typeof window !== 'undefined' ? window.navigator && window.navigator.userAgent : moment().unix().toString();
    const auth = (['settings'].includes(req.action) && method === 'put') ? '' : {
        time: moment().unix(),
        payload: req.data,
        endpoint: req.action,
        navigator,
    };
    let headers = {};
    if (type === 'file') {
        headers = func.config.headersFile;
    } else {
        headers = func.config.headers;
    }
    headers['x-access-token'] = `${func.encode(auth)}.${func.getStorage('token')}`;
    return headers;
}


export const post = async (action, data = {}) => {
    let headers = apiHeaders({ action, data });
    let empty = action.includes('http:') || action.includes('https:') ? true : false;
    let url = ((empty === false) ? func.config[func.config.env].url + action : action);
    try {
        let response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const get = async (action, data = {}) => {
    let headers = apiHeaders({ action, data });
    let empty = action.includes('http:') || action.includes('https:') ? true : false;
    let url = ((empty === false) ? func.config[func.config.env].url + action : action);
    return axios({
        method: 'GET', url,
        headers: empty ? {} : headers,
        params: data
    }).then(response => {
        return respunse(response.data);
    }).catch(error => {
        return { status: 606, result: 'Network request failed', error };
    });
}

export const put = async (action, data = {}) => {
    let headers = apiHeaders({ action, data }, '', 'put');
    let empty = action.includes('http:') || action.includes('https:') ? true : false;
    let url = ((empty === false) ? func.config[func.config.env].url + action : action);
    try {
        let response = await fetch(url, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const delte = async (action, data = {}) => {
    let headers = apiHeaders({ action, data });
    try {
        let response = await fetch(func.config[func.config.env].url + action, {
            method: 'DELETE',
            headers,
            body: JSON.stringify(data),
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const postFile = async (action, data = {}) => {
    let headers = apiHeaders({ action, data }, 'file');
    try {
        let response = await fetch(func.config[func.config.env].url + action, {
            method: 'POST',
            headers,
            body: apnData(data)
        });
        return respunse(await response.json());
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

const respunse = (res) => {
    if (res.status === 505 && (func.getStorageJson('user') || {}).uuid) {
        return signOutSuccess();
    } else if (res.token) {
        func.setStorage('token', res.token);
    }
    return res;
}