import moment from 'moment';
import { Modal, notification } from 'antd';
import { Buffer } from 'buffer';

export const config = {
    env: 'local',
    dbpref: 'fyv_hq_',
    version: require('../../package.json').version,
    local: {
        url: 'http://localhost/fayavotes/api/',
        web: 'http://localhost/fayavotes/web/',
        apiKey: 'PJkYEfGg9kuQIDFCRt8GU9w68RTpUUwQrCXZXzNLqK8RvY7tnW6CPtjXf2Rg9aeh',
        platform: 'localhost',
    },
    dev: {
        url: 'https://qa-api.fayavotes.com/',
        web: 'https://qa.fayavotes.com/',
        apiKey: 'xA227CapUtV4XVSngRzz7qYy5ItUsaIj4IAUr2zB3xgUepBwhgdFKTsvqhzzBDTu',
        platform: 'qa-hq',
    },
    live: {
        url: 'https://api.fayavotes.com/v1/',
        url2: 'https://votes-api.anchoratechs.com/v1/',
        web: 'https://fayavotes.com/',
        apiKey: '1gjxKn5k2jIEPkrpk5rWub6w3MBs2JP7wIkdXxZgztDaTl7ThWrjRIHnIdZSydKH',
        platform: 'hq',
    },
    apiToken: '',
    headers: {},
    headersFile: {},
};

export const app = {
    version: require('../../package.json').version,
}

export const initialize = () => {
    if (window.location.hostname === 'localhost') {
        config.env = 'local';
    } else if (window.location.host === 'qa-hq.fayavotes.com') {
        config.env = 'dev';
    } else {
        config.env = 'live';
    }
    config.apiToken = getStorage('token');

    config.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Platform': `${config[config.env].platform}/${app.version}`,
        'x-access-token': `${config[config.env].apiKey}.${config.apiToken}`
    };
    config.headersFile = {
        'Accept': 'application/json',
        'Platform': `${config[config.env].platform}/${app.version}`,
        'x-access-token': `${config[config.env].apiKey}.${config.apiToken}`
    };
}

export const dates = {
    yr: moment().format('YYYY'),
}


// ::: storage
export const setStorage = (key, value) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, value);
    }
}
export const getStorage = (key) => {
    const value = localStorage.getItem(config.dbpref + key);
    return value || '';
}
export const setStorageJson = (key, value) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, JSON.stringify(value));
    }
}
export const getStorageJson = (key) => {
    if (key) {
        const value = localStorage.getItem(config.dbpref + key);
        return JSON.parse(value) || '';
    }
}
export const delStorage = (key) => {
    if (key) {
        localStorage.removeItem(config.dbpref + key);
    }
}

export const mergeObj = (obj, src) => {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}
export const getFileExtension = (filename) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
}

// Spinners
export const fspinner = <div style={{ textAlign: 'center', color: '#999', lineHeight: 320 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-5x"></i></div>;
export const fspinner_sm = <div style={{ textAlign: 'center', color: '#999', lineHeight: 120 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-3x"></i></div>;
export const fspinner_xs = <i className="fa fa-spin fa-circle-o-notch"></i>;

export const redirect = (to) => {
    window.location = to;
}

export const generateOptions = (length, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
};

export const hasR = (role) => {
    const user = getStorageJson('user');
    const myRoles = ((user.access || {}).permissions || '').split(',');
    return (myRoles.includes(role) || myRoles.includes('*'));
};

export const numberFormat = (number, minimumFractionDigits = 0) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits }).format(number);
}

export const loading = (content) => {
    return new Promise((resolve) => {
        content = <div className="text-center" dangerouslySetInnerHTML={{ __html: `<i class="fa fa-spin fa-spinner"></i> <span>${content}</span>` }} />
        const loading = Modal.info({
            icon: null,
            title: null,
            centered: true,
            content,
            width: '250px',
            cancelText: <div />,
            okButtonProps: { style: { display: 'none' } },
            cancelButtonProps: { style: { display: 'none' } },
        });
        resolve(loading);
    });
}

export const alert = (title, content, props, onOK) => {
    Modal.warning({
        icon: null,
        title,
        centered: true,
        content,
        okText: 'Okay!',
        width: '300px',
        cancelText: <div />,
        ...props,
        // onOk: (close) => {
        //     close();
        //     onOK && onOK();
        // }
    });
}


export const randNum = (length = 6) => {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const randCode = (length = 6) => {
    var result = '';
    var characters = 'ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}


export const serviceFee = (a = {}) => {
    return +a.revenue * (+a.preferences.norm_vote_fee / 100);
}

export const revenue = (a = {}) => {
    return +a.revenue - serviceFee(a);
}


export const datesBetween = (startDate, endDate, format = 'YYYY-MM-DD') => {
    var dates = [];
    var currentDate = startDate;
    var addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };
    while (currentDate <= endDate) {
        var date = moment(currentDate).format(format);
        if (!dates.includes(date)) {
            dates.push(date);
        }
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
};

export const imgError = (e) => {
    e.target.src = '/assets/default.png';
}

export const encode = (str) => {
    var encoded = '';
    str = JSON.stringify(str);
    str = Buffer.from(str).toString('base64');
    str = Buffer.from(str).toString('base64');
    for (let i = 0; i < str.length; i++) {
        var a = str.charCodeAt(i);
        var b = a ^ 10;
        encoded = encoded + String.fromCharCode(b);
    }
    encoded = Buffer.from(encoded).toString('base64');
    return encoded;
}

export const copyToClipboard = (text) => {
    navigator.clipboard && navigator.clipboard.writeText(text).then((_) => {
        notification.success({ message: `Copied` });
    });
}

export const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

export const sort = {
    string: (a, b, key, sort = 'desc') => {
        const fa = a[key].toLowerCase();
        const fb = b[key].toLowerCase();

        if (fa < fb) {
            return sort === 'asc' ? -1 : 1;
        }
        if (fa > fb) {
            return sort === 'asc' ? 1 : -1;
        }
        return 0;
    },
    number: (a, b, key, sort = 'desc') => {
        const fa = a[key];
        const fb = b[key];

        if (fa < fb) {
            return sort === 'asc' ? -1 : 1;
        }
        if (fa > fb) {
            return sort === 'asc' ? 1 : -1;
        }
        return 0;
    }
}

export const unix = () => moment().unix().toString();

export const chunk = (inputArray, chunkSize) => {
    return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray
    }, []);
}

export const copyData = (data) => JSON.parse(JSON.stringify(data));

export const scrollToTop = () => {
    if (window.location.hostname !== 'localhost') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}