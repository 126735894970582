import React from 'react';
import ReactDOM from 'react-dom/client';
import { StoreProvider } from '_Store';
import HttpsRedirect from 'react-https-redirect';
import { ConfigProvider } from 'antd';

import './index.less';
import './assets/scss/custom.scss';

import App from './App';

import * as serviceWorker from './serviceWorker';

const antdTheme = {
    token: {
        colorPrimary: '#F91A31',
        // colorSecondary: '#FFCB00',
        // colorDanger: '#DA1E28',
        borderRadius: 5,
        fontFamily: '"Libre Franklin", sans-serif',
    },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <StoreProvider>
            <HttpsRedirect>
                <ConfigProvider theme={antdTheme}>
                    <App />
                </ConfigProvider>
            </HttpsRedirect>
        </StoreProvider>
    </React.StrictMode>
);

serviceWorker.unregister();
