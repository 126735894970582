import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { func, routes } from 'utils';
import { Loading } from 'components';

const Main = React.lazy(() => import('./Main'));
const Authenticate = React.lazy(() => import('./screens/Authenticate'));

const App = () => {

  const user = func.getStorageJson('user');
  const token = func.getStorage('token');
  const isLoggedIn = !!(user.uuid && token);

  const Layout = () => {
    return (
      <React.Fragment>
        {isLoggedIn ? (
          <Main>
            <Outlet />
          </Main>
        ) : (
          <Authenticate />
        )}
      </React.Fragment>
    );
  }

  const rautes = routes.filter(rt => func.hasR(rt.code));

  return (
    <React.Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loading spinning={true} />}>
          <Routes>
            <Route element={<Layout />}>
              {rautes.map(route => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
              <Route path="*" element={rautes[0]?.element} />
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </React.Fragment>
  );

}

export default App;