import React from 'react';
import { Button } from 'antd';
import { axius } from 'utils';
import moment from 'moment';


const ExportData = ({ name, data, mapData, fetchData }) => {

    const [loading, setLoading] = React.useState(false);

    const initiate = () => {
        if (fetchData) {
            setLoading(true);
            axius.get(fetchData.endpoint, fetchData.query).then(res => {
                downloadData(mapData(res.data));
                setLoading(false);
            });
        } else {
            downloadData(mapData(data));
        }
    }

    const objectToCsv = function (data) {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(Object.keys(data[0]).map(val => {
            return `"${val}"`;
        }).join(','));
        for (const row of data) {
            const values = headers.map(header => {
                const val = row[header];
                return `"${val}"`;
            });
            csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
    };

    const downloadData = (data) => {
        const csv = objectToCsv(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', `${name}-${moment().toISOString()}.csv`);
        a.click();
    }

    return (
        <React.Fragment>
            <Button type="default" className="text-muted" loading={loading} onClick={initiate}>
                Export data &nbsp; &nbsp; <i className="fas fa-download text-xs" />
            </Button>
        </React.Fragment>
    );

};

export default ExportData;