import React from 'react';
import { func } from 'utils';

const Store = React.createContext({});

export const StoreProvider = (props) => {

    const [user, setUser] = React.useState(func.getStorageJson('user') || {});
    const [token, setToken] = React.useState(func.getStorage('token'));
    const [_utils, setUtils] = React.useState({
        limit: 11,
        loading: {
            primary: '',
            spinning: false,
            secondary: '',
        }
    });

    const [_data, setData] = React.useState({
        awards: func.getStorageJson('awards') || [],
        settings: func.getStorageJson('settings') || {},
        countries: func.getStorageJson('countries') || [],
        organisation: func.getStorageJson('organisation') || {},
        organisations: func.getStorageJson('organisations') || [],
        navigation: [
            { code: 'dash', name: 'Overview', icon: 'fas fa-fw fa-cubes', link: '', subs: [] },
            {
                code: 'awd', name: 'Awards', icon: 'fas fa-fw fa-star', link: 'awards',
                subs: [],
            },
            {
                code: 'org', name: 'Organisations', icon: 'fas fa-fw fa-building', link: 'organisations',
                subs: [],
            },
            {
                code: 'trs', name: 'Transfers', icon: 'fas fa-fw fa-wallet', link: 'transfers',
                subs: [],
            },
            {
                code: 'usr', name: 'Users', icon: 'fas fa-fw fa-users', link: 'users', subs: [],
            },
            {
                code: 'set', name: 'Settings', icon: 'fas fa-fw fa-cogs', link: 'settings',
                subs: [
                    { code: 'set_set', name: 'General settings', link: 'settings', rules: [] },
                    { code: 'set_frx', name: 'Forex', link: 'settings/forex', rules: [] },
                ]
            },
            {
                code: 'rep', name: 'Reports', icon: 'fas fa-layer-group', link: 'reports',
                subs: [
                    { code: 'rep_pay', name: 'Payments', link: 'reports/payments', rules: [] },
                    { code: 'rep_vot', name: 'Votes', link: 'reports/votes', rules: [] },
                    { code: 'rep_prof', name: 'Profits', link: 'reports/profits', rules: [] },
                    { code: 'rep_gate', name: 'Recons', link: 'reports/gateways', rules: [] },
                ]
            },
            { code: 'ussd', name: 'USSD', icon: 'fas fa-fw fa-code', link: 'ussd', subs: [] },
            { code: 'cmp', name: 'Competitions', icon: 'fas fa-fw fa-home', link: 'competitions', subs: [] },
        ]
    });

    const [_cache, setCache] = React.useState({
        user: func.unix(),
    });

    const _store = { _auth: { user, token }, _utils, _data, _cache };

    const _setUser = (value) => {
        const newVlaue = { ...user, ...value };
        setUser(newVlaue);
        func.setStorageJson('user', newVlaue);
    }

    const _setToken = (value) => {
        setToken(value);
        func.setStorage('token', value);
    }

    const _setAuth = (token, user) => {
        _setUser(user);
        _setToken(token);
        func.setStorage('token', token);
        func.setStorageJson('user', user);
    }

    const _setUtils = (key, value) => {
        if (typeof value === 'string') {
            func.setStorage(key, value);
            setUtils((su) => ({ ...su, [key]: value, }));
        } else {
            func.setStorageJson(key, value);
            setUtils((su) => ({ ...su, [key]: { ...su[key], ...value }, }));
        }
    }

    const _setData = (key, value) => {
        if (typeof value === 'string') {
            func.setStorage(key, value);
            setData((sd) => ({ ...sd, [key]: value, }));
        } else {
            func.setStorageJson(key, value);
            setData((sd) => ({ ...sd, [key]: value }));
            // setData((prevState) => ({ ...prevState, [key]: { ...prevState[key], ...value }, }));
        }
    }

    const _setCache = (key, value) => {
        func.setStorage('cache_' + key, value);
        setCache((prevState) => ({ ...prevState, [key]: value, }));
    }

    return (
        <Store.Provider value={{ _store, _setAuth, _setUser, _setToken, _setUtils, _setData, _setCache }}>
            {props.children}
        </Store.Provider>
    );

}

export default Store;