import React from 'react';
import { Button, DatePicker, Input, Popover, Select } from 'antd';


const Filter = ({ inputs, value, onSearch }) => {

    const [v, setValue] = React.useState({});
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (value) {
            setValue(value);
        }
    }, [value]);

    const reset = () => {
        const vv = {};
        for (let i = 0; i < inputs.length; i++) {
            vv[inputs[i].name] = '';
        }
        setOpen(false);
        setValue({});
        onSearch(vv);
    }

    const submit = () => {
        setOpen(false);
        onSearch(v);
    }

    const content = (
        <div id="Filter" className="p-2" style={{ width: 270 }}>
            {inputs.map(input => (
                <div key={input.name} className="mb-3">
                    {input.label && (
                        <label htmlFor={input.name} className="mb-1">{input.label}</label>
                    )}
                    {input.type === 'input' && (
                        <Input
                            id={input.name}
                            size="large"
                            value={v[input.name] || ''}
                            placeholder={input.label}
                            autoComplete="off"
                            onChange={e => setValue(sv => ({ ...sv, [input.name]: e.target.value }))}
                            onPressEnter={submit}
                        />
                    )}
                    {input.type === 'select' && (
                        <Select
                            id={input.name}
                            size="large"
                            showSearch
                            optionFilterProp="children"
                            value={v[input.name] || ''}
                            autoComplete="off"
                            onChange={e => setValue(sv => ({ ...sv, [input.name]: e }))}
                        >
                            <Select.Option value="">Show all</Select.Option>
                            {(input.options || []).map(option => (
                                <Select.Option key={`${input.name}_${(option.value || option)}`} value={(option.value || option).toString()}>
                                    {(option.label || option)}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                    {input.type === 'date_range' && (
                        <div className="mb-6">
                            <DatePicker.RangePicker
                                size="large"
                                format="MMM DD, YYYY"
                                // value={v[input.name] ? [dayjs(v[input.name][0]), dayjs(v[input.name][1])] : null}
                                className="w-full"
                                onChange={e => {
                                    if (e) {
                                        setValue(sv => ({
                                            ...sv,
                                            between: `crdate_${e[0]?.format('YYYY-MM-DD')}_${e[1]?.format('YYYY-MM-DD')}`,
                                            [input.name]: `${e[0]?.format('YYYY-MM-DD')},${e[1]?.format('YYYY-MM-DD')}`,
                                        }));
                                    } else {
                                        setValue(sv => ({
                                            ...sv,
                                            between: ``,
                                            [input.name]: ``,
                                        }));
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
            ))}

            <hr />

            <div className="d-flex justify-content-between">
                <Button type="default" onClick={reset}>Reset</Button>
                <Button type="primary" onClick={submit}>Filter</Button>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <div className="d-flex align-items-center">
                {Object.keys(v).length > 0 && (
                    <div className="mr-2 border px-2 rounded bg-white" style={{ paddingTop: 5.8, paddingBottom: 5.8 }}>
                        {Object.keys(v).filter(vv => !['between'].includes(vv)).map(vv => {
                            const input = inputs.find(i => i.name === vv) || {};
                            const value = input.type === 'select' ? input.options.find(o => (o.value || o).toString() === v[vv])?.label : v[vv];
                            return (
                                v[vv] && <span key={input.name} className="mr-1 ml-1">{input.label || input.name}: {value || v[vv]}</span>
                            )
                        })}
                        <i className="fas fa-times-circle p-1s pointer ml-1 text-danger" onClick={reset} />
                    </div>
                )}
                <Popover
                    open={open}
                    content={content}
                    title={null}
                    trigger="click"
                    placement="bottomLeft"
                    onOpenChange={setOpen}
                >
                    <Button type="default" className="text-muted">Filters &nbsp; &nbsp; <i className="fas fa-angle-down text-xs" /></Button>
                </Popover>
            </div>
        </React.Fragment>
    );
};

export default Filter;